// 一、配置axios
import axios from 'axios'
import { Toast } from 'vant'
// import store from '@/store/index' 如果使用vuex，那么token，userinfo都可以在登录以后存储到store中，不需要使用storage
// 获取浏览器的接口地址。

let baseUrl = ""
if (process.env.NODE_ENV === 'development') {
  baseUrl = "http://localhost:8200"
} else {
  baseUrl = "https://api.qtemai.com"
}


// axios配置
axios.defaults.baseURL = baseUrl
// 设置请求最大时长
axios.defaults.timeout = 50000
// axios.defaults.withCredentials = true
// axios.defaults.headers.post["Origin"] = "http://h5.qtemai.com"
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// 请求拦截器，设置token
axios.interceptors.request.use(config => {
  if (localStorage && localStorage.getItem('token')) {
    const token = localStorage.getItem('token')
    token && (config.headers.Authorization = 'Bearer ' + token)
  }
  return config
}, error => {
  // 可以安装elementui等ui组件，将错误信息输出到界面。
  console.log(error)
  return Promise.error(error)
})
// 响应拦截器
axios.interceptors.response.use(response => {
  if (response.status === 200) {
    if (response.data.code === -105) {
      window.location.href = '/#/subscribe'
    } else if (response.data.code !== -101 && response.data.code !== -102) {
      return Promise.resolve(response)
    } else {
      localStorage.removeItem("token")
      window.location.href = '/#/login'
    }
  } else {
    Toast.clear()
    Toast("系统错误")
    return Promise.reject(response)
  }
})

// 2、封装请求方式
// @param url 接口地址
// @param data 携带参数
// @param file 上传文件对象
// @param auth 是否携带token
// get请求
export function get (url, data) {
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 30 * 1000
  });
  return axios.get(url, {
    params: data
  })
}
// post请求
export function post (url, data) {
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 30 * 1000
  });
  return axios.post(url, data)
}
// put请求
export function put (url, data) {
  return axios.put(url, data)
}
// delete 请求
export function del (url, data) {
  return axios.delete(url, data)
}
// upload 请求
export function uploader (url, file) {
  let params = new FormData()
  params.append('file', file)
  return axios.post(url, params)
}
