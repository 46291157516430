// 封装后台接口方法
import { post,get } from './http'
export const WX_LOGIN = (params) => post('wxoa/login', params, false)
export const WX_JSSDK = (params) => post('wxoa/jssdk', params, false)
export const WX_CONFIG = (params) => get('wxoa/config', params, false)
export const USER_GETINFO = (params) => get('user/profile', params, false)

export const HPV_SUBSCRIBE = (params) => post('hpv/subscribe', params, false)
export const HPV_SUBSCRIBE_SETTING = (params) => get('hpv/subscribe/setting', params, false)
export const VIP_PACKAGES = (params) => get('vip/packages', params, false)
export const VIP_OPEN = (params) => post('vip/create', params, false)
export const CHAT_GROUP = (params) => get('user/chat_group', params, false)
export const QRCODE_LIMIT = (params) => get('user/qrcode/limit', params, false)
export const QRCODE_FETCH = (params) => get('user/qrcode/fetch', params, false)

export const CHANNEL_STATE = (params) => get('/channel/order_state', params, false)
export const SETTLE_LIST = (params) => get('/settle/list', params, false)